import { getClaimsByFunnelId, getFunnelVariables } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { convertTimestampToDate } from "./convertTimestampToDate";
import { Timestamp } from "firebase/firestore";

export async function getUsersPcpLenders(userId: string, funnelId: string) {
  const [claims, error] = await getClaimsByFunnelId(getFirebaseBackend().firestore(), userId, funnelId);
  if (error) {
    throw new Error("Failed to get users pcp lenders");
  }

  const [funnelVariables, funnelVariablesError] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);

  if (funnelVariablesError) {
    throw new Error("Failed to get funnel variables");
  }

  const findVariableId = (field: string) => funnelVariables.find((variable) => variable.field === field)?.id;

  const variableId = {
    caseType: findVariableId("caseType"),
    keeperStartDate: findVariableId("keeperStartDate"),
    craCreditStartDate: findVariableId("craCreditStartDate"),
    financeLender: findVariableId("financeLender"),
    craFinanceLender: findVariableId("craFinanceLender"),
    enteredRegistration: findVariableId("enteredRegistration"),
  };

  const claimsWithNewPcpLenders = claims
    .filter((claim) => {
      return claim[variableId.caseType] !== undefined;
    })
    .map((claim) => {
      if (claim[variableId.caseType] === "Credit Checked" && !!claim[variableId.craFinanceLender]) {
        return {
          start:
            claim[variableId.craCreditStartDate] instanceof Timestamp
              ? convertTimestampToDate(claim[variableId.craCreditStartDate])
              : claim[variableId.craCreditStartDate],
          companyName: claim[variableId.craFinanceLender],
          caseType: claim[variableId.caseType],
        };
      }
      if (claim[variableId.caseType] === "Manual Entry" && !!claim[variableId.financeLender]) {
        return {
          start:
            claim[variableId.keeperStartDate] instanceof Timestamp
              ? convertTimestampToDate(claim[variableId.keeperStartDate])
              : claim[variableId.keeperStartDate],
          companyName: claim[variableId.financeLender],
          enteredRegistration: claim[variableId.enteredRegistration],
          caseType: claim[variableId.caseType],
        };
      } else {
        return null;
      }
    })
    .filter((claim) => claim !== null);

  return claimsWithNewPcpLenders;
}
