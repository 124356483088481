<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
//Components
//import ClaimsGateButton from "@/components/shared/styleWrappers/button/claimsgateButton.vue";
import SimpleName from "@/components/shared/blocks/simpleName.vue";
import DateOfBirth from "@/components/shared/blocks/user/dateofbirth.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
import CheckboardAboutCard from "./checkboardAboutCard.vue";
import BlockButton from "@/components/shared/blocks/button.vue";

//Helpers
import { viewSubmit } from "../helpers/viewSubmit";
import { gatewayHelper } from "../helpers/gatewayHelper";
import { handleUnknownError } from "../helpers/handleUnknownError";
import { baseProps } from "../helpers/baseProps";
//Types
import type { ApproveCheck } from "../helpers/CheckboardCreditCheck";
import { navigationComputed, themesComputed } from "@/state/helpers";

export default (Vue as VueConstructor<Vue & ApproveCheck.Refs>).extend({
  name: "ApproveCheck",
  computed: {
    ...navigationComputed,
    ...themesComputed,
  },
  components: { SimpleName, DateOfBirth, CheckboardHeaderContent, CheckboardAboutCard, BlockButton },
  props: {
    ...baseProps(),
    userFirstName: {
      type: String as PropType<string>,
      required: true,
    },
    userLastName: {
      type: String as PropType<string>,
      required: true,
    },
    userDob: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      userConsent: "none" as "none" | "approved" | "declined",
      user: {
        firstName: this.userFirstName,
        lastName: this.userLastName,
      },
      dob: this.userDob,
      isSubmitting: false,
    };
  },
  methods: {
    updateDobAnswer(newValue: string) {
      // formats data string returned from DOB component to YYYY-MM-DD
      if (newValue && newValue.includes("T")) {
        newValue = newValue.substring(0, newValue.indexOf("T"));
      }
      this.dob = newValue;
    },
    async submit() {
      if (this.$refs.simpleName.validations()) {
        const nameInputs = this.$refs.simpleName.computes();
        this.user.firstName = nameInputs.firstName;
        this.user.lastName = nameInputs.lastName;
        if (this.$refs.dob.validations()) {
          this.$refs.dob.computes();
          await this.approveCheck();
        }
      }
    },
    async approveCheck() {
      try {
        this.isSubmitting = true;

        this.updateDobAnswer(this.dob);

        const data = {
          claimId: this.claimId,
          userId: this.userId,
          checkId: this.checkId,
          approved: true,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          dob: this.dob,
          allowCheckboardFallback: this.allowCheckboardFallback,
          headless: false,
          enforceQualificationPeriod: this.enforceQualificationPeriod,
        };

        //potential errors are caught in the gatewayHelper
        const result = await gatewayHelper("checkboardApproveCheck", data);
        // Uncomment below to test edge case where client is navigated to
        // addressConfirmation without any addresses returned from checkboard.
        // if (result.clientView === "addressConfirmation") {
        //   result.addresses = [];
        // }
        viewSubmit(this, "approveCheck", result);
      } catch (error) {
        console.error("Error approving check:", this.check.id, error);
        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
    async clickedApproveCheck() {
      if (this.user.firstName && this.user.lastName && this.dob) {
        await this.approveCheck();
      } else {
        this.userConsent = "approved";
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.poppins {
  font-family: "Poppins", sans-serif !important;
}
</style>
<template>
  <div>
    <div v-if="userConsent === 'none'" class="text-center mx-auto">
      <div class="d-flex flex-column gap-20">
        <div>
          <CheckboardHeaderContent
            :isBuilder="isBuilder"
            :hideProgressBar="hideProgressBar"
            :progressValue="'33'"
            :header="'Verify Your Financial Agreement'"
            :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, we need to verify your financial agreement details to strengthen your claim`"
          />
        </div>

        <div>
          <CheckboardAboutCard />
        </div>

        <div class="text-left">
          <h5 class="text-sm text-grey-500 font-weight-normal poppins">
            By clicking 'Proceed', you agree to {{ navigationWorkspaceName }} verifying your financial agreement
            details. This includes a soft credit check that won't affect your credit score.
          </h5>
        </div>

        <div>
          <BlockButton
            :text="'Proceed with verification'"
            :variant="'primary'"
            :isProcessing="isSubmitting"
            @clicked="clickedApproveCheck"
            :block="false"
            :submitButtonDefault="false"
          />
          <!--<ClaimsGateButton
          class="mr-2"
          variant="primary"
          text="Proceed with verification"
          @click="clickedApproveCheck"
        />-->
        </div>
      </div>
    </div>
    <!-- Assuming this view will never be shown, as a user will always have dob as well as  first and last name -->
    <div v-if="userConsent === 'approved'" class="text-center mx-auto">
      <div class="d-flex flex-column gap-20">
        <div>
          <CheckboardHeaderContent
            :isBuilder="isBuilder"
            :progressValue="'33'"
            :hideProgressBar="hideProgressBar"
            :header="'Verify Your Financial Agreement'"
            :subtitle="`We require the following information to start your credit check`"
          />
        </div>

        <SimpleName :title="false" :middleName="false" :answer="user" ref="simpleName" padding="5" />

        <DateOfBirth v-bind="{ inputType: 'text' }" :answer="dob" @update:answer="updateDobAnswer" ref="dob" />

        <BlockButton
          :text="'Submit'"
          :variant="'primary'"
          :isProcessing="isSubmitting"
          @clicked="submit"
          :block="false"
          :submitButtonDefault="false"
        />
      </div>
    </div>
  </div>
</template>
