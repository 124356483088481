import { render, staticRenderFns } from "./initiateCheck.vue?vue&type=template&id=64ce341a&scoped=true"
import script from "./initiateCheck.vue?vue&type=script&lang=ts"
export * from "./initiateCheck.vue?vue&type=script&lang=ts"
import style0 from "./initiateCheck.vue?vue&type=style&index=0&id=64ce341a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ce341a",
  null
  
)

export default component.exports