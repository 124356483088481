import { OnCallGateway, StandardAsyncResult } from "@claimsgate/core-types";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
type OnCallArg<F extends keyof OnCallGateway.Functions> = {
  functionName: F;
  data?: Parameters<OnCallGateway.Functions[F]>[0];
};

type OnCallCrmArg<F extends keyof OnCallGateway.CrmFunctions> = {
  functionName: F;
  data?: Parameters<OnCallGateway.CrmFunctions[F]>[0];
};

export type OnCallGatewayReturn<F extends keyof OnCallGateway.Functions> =
  | StandardAsyncResult<never, never, any>
  | ReturnType<OnCallGateway.Functions[F]>;

export type OnCallCrmGatewayReturn<F extends keyof OnCallGateway.CrmFunctions> =
  | StandardAsyncResult<never, never, any>
  | ReturnType<OnCallGateway.CrmFunctions[F]>;

function getGatewayFunctionUrl() {
  if (process.env.VUE_APP_RUNNING_FROM_EMULATOR === "true" && process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    return "http://localhost:5001/claimsgate-4cda5/europe-west2/gateway"; // for ./runDEv.ps1
  } // ! You might need to add :{portNumber} you are running localhost on
  else if (
    process.env.VUE_APP_RUNNING_FROM_EMULATOR === "true" &&
    process.env.VUE_APP_PROJECTId === "dev-claims-cake"
  ) {
    return "http://localhost:5001/dev-claims-cake/europe-west2/gateway"; // for ./runDev.ps1
    // return "http://localhost:5001/claimsgate-4cda5/europe-west2/gateway"; // for /runProd.ps1
  } else if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    return "https://gateway-ep47tzgkqa-nw.a.run.app";
  } else if (process.env.VUE_APP_PROJECTId === "dev-claims-cake") {
    return "https://gateway-xxadm3na2q-nw.a.run.app";
  } else {
    return "https://gateway-xxadm3na2q-nw.a.run.app";
  }
}

/**
 * Provides access to the gateway function. Use the generic to enable strong type definitions.
 * @param fire The name of function to be called, and any arguments to be passed with it
 * @returns
 */
export async function onCallGateway<F extends keyof OnCallGateway.Functions>(
  fire: OnCallArg<F>
): Promise<OnCallGatewayReturn<F>> {
  const gateway = httpsCallableFromURL(getFunctions(), getGatewayFunctionUrl());
  return gateway(fire) as OnCallGatewayReturn<F>;
}

function getGatewayCrmFunctionUrl() {
  if (process.env.VUE_APP_RUNNING_FROM_EMULATOR === "true" && process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    return "http://localhost:5001/claimsgate-4cda5/europe-west2/crmGateway"; // for ./runDEv.ps1
  } // ! You might need to add :{portNumber} you are running localhost on
  else if (window.location.host === "localhost") {
    return "http://localhost:5001/dev-claims-cake/europe-west2/crmGateway"; // for ./runDev.ps1
    // return "http://localhost:5001/claimsgate-4cda5/europe-west2/gateway"; // for /runProd.ps1
  } else if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    return "https://crmgateway-ep47tzgkqa-nw.a.run.app";
  } else if (process.env.VUE_APP_PROJECTId === "dev-claims-cake") {
    return "https://crmgateway-xxadm3na2q-nw.a.run.app";
  } else {
    return "https://crmgateway-xxadm3na2q-nw.a.run.app";
  }
}

export async function onCallCrmGateway<F extends keyof OnCallGateway.CrmFunctions>(
  fire: OnCallCrmArg<F>
): Promise<OnCallCrmGatewayReturn<F>> {
  const gateway = httpsCallableFromURL(getFunctions(), getGatewayCrmFunctionUrl());
  return gateway(fire) as OnCallCrmGatewayReturn<F>;
}
