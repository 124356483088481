<script lang="ts">
import Vue, { VueConstructor } from "vue";
// Components
import Padding from "../padding.vue";
import InitiateCheck from "./views/initiateCheck.vue";
import ApproveCheck from "./views/approveCheck.vue";
import AddressInput from "./views/addressInput.vue";
import AddressConfirmation from "./views/addressConfirmation.vue";
import AddressHistory from "./views/addressHistory.vue";
import RequestorAuthenticity from "./views/requestorAuthenticity.vue";
import ConfirmLenders from "./views/confirmLenders.vue";
import Success from "./views/successCheck.vue";
import Fail from "./views/failCheck.vue";
import { getClaimDataService } from "@/helpers/vue";
// Component files
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
import { initiateCheck, getUserData, handleSubmit } from "./methods";
// Types
import type { ViewSubmitEvent } from "./helpers/CheckboardCreditCheck";
import type { CreditCheckInstance } from "./creditCheckInstance";
// Helpers

export default (Vue as VueConstructor<Vue & CreditCheckInstance>).extend({
  name: "CreditCheck",
  components: {
    Padding,
    InitiateCheck,
    ApproveCheck,
    AddressInput,
    AddressConfirmation,
    AddressHistory,
    RequestorAuthenticity,
    ConfirmLenders,
    Success,
    Fail,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    ...(mappedComputed() as ReturnType<typeof mappedComputed>),
    currentViewProps(): ReturnType<typeof computed.currentViewProps> {
      return computed.currentViewProps(this);
    },
  },
  methods: {
    async initiateCheck() {
      await initiateCheck(this);
    },
    async getUserData() {
      return await getUserData(this);
    },
    handleSubmit(event: ViewSubmitEvent) {
      handleSubmit(event, this);
    },
  },
  async mounted() {
    try {
      if (this.isBuilder) {
        this.currentView = "approveCheck";
        return;
      }
      this.user = await this.getUserData();
      console.log("user", this.user);

      this.workspaceId = this.navigationWorkspaceId;

      this.userId = this.user.userId;

      await getClaimDataService(this);

      await this.initiateCheck();
    } catch (error) {
      console.error("mounted - error", error);
      this.currentView = "fail";
    }
  },
  watch: {
    currentViewProps: {
      handler(newVal) {
        console.log("Parent - props updated", newVal);
      },
      deep: true,
    },
  },
});
</script>

<template>
  <div>
    <Padding :padding="padding" />

    <component :is="currentView" v-bind="currentViewProps" @viewSubmit="handleSubmit($event)" />
  </div>
</template>
