import { getFirebaseBackend } from "../../authUtils";
import { DateService } from "./DateService";
import { AsyncHelper } from "./AsyncHelper";
/**
 * A type checking and translation service for variables
 */
class VariableService {
  db = null;

  constructor(db) {
    if (db) {
      this.db = db;
    } else {
      this.db = getFirebaseBackend().firestore();
    }
  }
  /**
   * Checks if the type of a value is the expected type
   * @param {*} value - the value to check
   * @param {*} type - the expected type
   */
  validate(value, type) {
    window.console.log("validating", value, type);
    if (type === "string") {
      return typeof value === "string" || value instanceof String;
    } else if (type === "number") {
      return isNaN(value) ? false : true;
    } else if (type === "boolean") {
      return value === true || value === false;
    } else if (type === "date") {
      return Object.prototype.toString.call(value) === "[object Date]";
    } else if (type === "array") {
      return Array.isArray(value);
    } else if (type === "file") {
      return value.file instanceof File;
    } else if (type === "object") {
      return typeof value === "object";
    } else if (type === "array of files") {
      return Array.isArray(value.file) && value.file.every((file) => file instanceof File);
    } else {
      false;
    }
  }

  /**
   * Converts Firebase Types to their Javascript equivalents
   * @param {*} value - the value
   * @param {*} type - the type of value
   */
  convert(value, type) {
    const dateService = new DateService();
    if (type === "date") {
      return dateService.parseDate(value);
    } else {
      return value;
    }
  }

  /**
   * Parse Claims Gate types to their Javascript/Firebase types
   * @param {*} value - the value
   * @param {*} type - the target type
   */
  parseValue(value, type) {
    if (type === "date") {
      const dateService = new DateService();
      const date = dateService.parseDate(value);
      return date;
    } else if (type === "number") {
      try {
        return Number(value);
      } catch {
        return value;
      }
    } else {
      return value;
    }
  }

  /**
   * Converts Firebase types to User friendly types
   * @param {*} value - the value
   * @param {*} type - the target type
   */
  translate(value, type) {
    if (type === "date") {
      const dateService = new DateService();
      const date = dateService.parseDate(value);

      //window.console.log("[translateDate]", value, date);
      if (date) {
        return dateService.toString(date);
      } else {
        return null;
      }
    } else if (type === "file") {
      return value;
    } else if (type === "agreement") {
      return value;
    } else {
      return value;
    }
  }

  /**
   * Translates an object to their hashed equivalents such that the data
   * is stored correctly in Firebase
   */
  async hashData(object, funnelVariables) {
    // Defensive check
    if (!object) {
      return null;
    }

    const { currentFunnelId } = object;
    let translatedData = {};

    if (funnelVariables == undefined) {
      let funnelVariablesRef;
      // Defensive check
      if (!currentFunnelId) {
        funnelVariablesRef = this.db.collection("variables");
      } else {
        funnelVariablesRef = this.db.collection("funnels").doc(currentFunnelId).collection("variables");
      }

      // Fetch the list of variables associated with this funnel

      const funnelVariablesQuerySnapshot = await funnelVariablesRef.get();

      funnelVariables = funnelVariablesQuerySnapshot.docs.map((document) => document.data());
    }

    for (const [key, value] of Object.entries(object)) {
      // Since funnel variables is an array of objects, you could not use .includes() here
      const variable = funnelVariables.find((variable) => variable.field.toLowerCase() === key.toLowerCase());

      // If there is a translation, we will output the translation
      // otherwise we will check if the key is not part of an exclusion list
      if (variable) {
        const { id, type } = variable;
        const translatedValue = this.convert(value, type);
        translatedData[id] = translatedValue;
      } else {
        translatedData[key] = value;
      }
    }
    return translatedData;
  }

  /**
   *    * Translates the hashed claim data into a human readable format
   * @param {*} claimData
   * @param {*} [funnelVariables]
   * @param {*} [fieldsToExclude]
   * @returns
   */
  async translateData(claimData, funnelVariables, fieldsToExclude = ["createdAt", "lastUpdated"]) {
    // Defensive check
    if (!claimData) {
      return null;
    }

    const { currentFunnelId } = claimData;
    let translatedData = {};

    if (funnelVariables == undefined) {
      let funnelVariablesRef;
      // Defensive check
      if (!currentFunnelId) {
        funnelVariablesRef = this.db.collection("variables");
      } else {
        funnelVariablesRef = this.db.collection("funnels").doc(currentFunnelId).collection("variables");
      }

      // Fetch the list of variables associated with this funnel

      const funnelVariablesQuerySnapshot = await funnelVariablesRef.get();

      funnelVariables = funnelVariablesQuerySnapshot.docs.map((document) => document.data());
    }

    // For each key and value in the claim data
    // Translate the hashed key, if necessary to its human readable equivalent

    for (const [key, value] of Object.entries(claimData)) {
      // Since funnel variables is an array of objects, you could not use .includes() here
      const variable = funnelVariables.find((variable) => variable.id === key);

      // If there is a translation, we will output the translation
      // otherwise we will check if the key is not part of an exclusion list
      if (variable) {
        const { field, type, structure } = variable;
        let translatedValue;
        if (type === "Array<T>" && structure) {
          translatedValue = value.map((entity) => {
            const translatedEnity = {};
            Object.entries(entity).forEach(([entityKey, entityValue]) => {
              const entityVariable = structure.find((entityVariable) => entityVariable.id === entityKey);
              if (entityVariable) {
                const { field, type } = entityVariable;
                translatedEnity[field] = this.translate(entityValue, type);
              }
            });
            return translatedEnity;
          });
        } else {
          translatedValue = this.translate(value, type);
        }
        if (translatedValue) {
          translatedData[field] = translatedValue;
        } else {
          // If the value could not be translated for example
          // the date was invalid we will set it to its default
          translatedData[field] = value;
        }
      } else {
        // If the key has no translation - check if it is part of the exclusion list
        // If the key is not part of the list AND the key is not a field that conflicts with a translated variable, we will also add it to the output

        if (!fieldsToExclude.includes(key) && funnelVariables.find((variable) => variable.field === key) == undefined) {
          translatedData[key] = value;
        }
      }
    }
    return translatedData;
  }

  /**
   * Translates the hashed claim data into a human readable format
   * @param {Object} claimData - Data to translate
   * @returns  {Object} - translated data
   */
  async getVariableList(claimData) {
    const { currentFunnelId } = claimData;

    let funnelVariablesRef;
    // Defensive check
    if (!currentFunnelId) {
      funnelVariablesRef = this.db.collection("variables");
    } else {
      funnelVariablesRef = this.db.collection("funnels").doc(currentFunnelId).collection("variables");
    }
    const funnelVariablesSnapshot = await funnelVariablesRef.get();

    const collectionVariables = funnelVariablesSnapshot.docs.map((doc) => doc.data());

    const internationalisationVariables = [
      {
        field: "country",
        id: "BXhEqFATYN4iyJpZrjKG",
        group: "user",
        type: "string",
      },
      {
        field: "phoneNumberCountryCode",
        id: "nUhDTFanATGYNDP0ue1W",
        group: "user",
        type: "string",
      },
    ];

    const variables = [...collectionVariables, ...internationalisationVariables];
    return AsyncHelper.onCompleted(variables);
  }
}

export { VariableService };
