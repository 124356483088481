import { PropType } from "vue";
import type { Check } from "./CheckboardCreditCheck";
export function baseProps() {
  return {
    claimId: {
      type: String as PropType<string>,
      required: true,
    },
    userId: {
      type: String as PropType<string>,
      required: true,
    },
    checkId: {
      type: String as PropType<string>,
      required: true,
    },
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    isBuilder: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "20",
    },
    allowCheckboardFallback: {
      type: Boolean,
      default: false,
    },
    useValidateRunCheck: {
      type: Boolean,
      default: false,
    },
    headless: {
      type: Boolean,
      default: false,
    },
    hideProgressBar: {
      type: Boolean,
      default: false,
    },
    skipApproveCheck: {
      type: Boolean,
      default: false,
    },
    extraClaimsPageId: {
      type: String,
      default: null,
    },
    enforceQualificationPeriod: {
      type: Boolean,
      default: true,
    },
  };
}
