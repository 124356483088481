/**
 * Users.js is a Vue X state manager which helps centralize key data points between
 * the users CRM and its child components
 */
export const state = {
  loaders: { query: false, queryTable: false, queryRoutes: false, queryReminderMessages: false },
};

export const getters = {
  /**
   * Returns each of the loaders
   */
  getLoaders(state) {
    return state.loaders;
  },
};

/**
 * Defines the mutations which mutate the Vue X state
 */
export const mutations = {
  toggleLoader(state, loaderName) {
    state.loaders[loaderName] = !state.loaders[loaderName];
  },

  setLoader(state, loaderDetails) {
    const { name, value } = loaderDetails;
    state.loaders[name] = value;
  },

  initalizeLoaders(state) {
    Object.keys(state.loaders).forEach((loaderName) => {
      state[loaderName] = false;
    });
  },
};
/**
 * Defines the actiosn which can occur to trigger a mutation
 */
export const actions = {
  toggleLoader({ commit }, loaderName) {
    commit("toggleLoader", loaderName);
  },

  setLoader({ commit }, loaderDetails) {
    commit("setLoader", loaderDetails);
  },
  initalize({ commit }) {
    commit("initalizeLoaders");
  },
};
