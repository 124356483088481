var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column gap-8"},[(!!_vm.progressValue && _vm.progressValue !== '0' && !_vm.hideProgressBar)?_c('div',{staticClass:"flex-column align-items-center justify-content-center pb-4"},[_c('BlockProgress',_vm._b({},'BlockProgress',{
        value: _vm.progressValue,
        variant: 'primary',
        height: '10',
        animated: false,
        width: 'sm',
        align: 'center',
      },false)),_c('BlockText',{attrs:{"text":'Agreement Verification ' + _vm.progressValue + '%',"isBuilder":_vm.isBuilder,"weight":'bold',"styles":'h5',"align":'center',"textColour":'text-primary',"padding":'10'}})],1):_vm._e(),(_vm.header)?_c('div',[_c('BlockTitle',{attrs:{"text":_vm.header,"styles":'h3',"align":'left',"weight":'bold',"textColour":'text-primary'}})],1):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"mt-2"},[_c('BlockSubtitle',{attrs:{"text":_vm.subtitle,"styles":'h5',"align":'left',"weight":'semibold',"textColour":'text-body',"padding":'0'}},[_vm._t("subtitle")],2)],1):_vm._e(),(_vm.text)?_c('div',[_c('h5',{staticClass:"text-sm text-grey-500 font-weight-semibold",style:([_vm.theme.fontStyles, _vm.theme.primaryStyles]),domProps:{"innerHTML":_vm._s(_vm.text)}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }