<script lang="ts">
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ClaimsGateButton",
  props: {
    text: {
      type: String as PropType<string>,
      required: false,
    },
    icon: {
      type: [String, Object, Array, null] as PropType<string | object | Array<string | object> | null>,
      required: false,
      default: null,
    },
    variant: {
      type: String as PropType<"primary" | "secondary-grey" | "secondary-colour" | "tertiary-colour">,
      default: "primary",
    },
    block: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    size: {
      type: String as PropType<"sm" | "lg">,
      default: "lg",
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loadingText: {
      type: String as PropType<string>,
      default: "Loading",
    },
    loadingIcon: {
      type: String as PropType<string>,
      default: "spinner-border-sm",
    },
    bIcon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    iconPosition: {
      type: String as PropType<"trailing" | "leading">,
      default: "leading",
    },

    // extraFunctionality: {
    //   type: Boolean as PropType<boolean>,
    //   default: false,
    // },
  },

  computed: {
    computedVariant() {
      if (this.variant === "primary") {
        return "cg-primary";
      }
      if (this.variant === "secondary-grey") {
        return "cg-secondary-grey";
      }
      if (this.variant === "secondary-colour") {
        return "cg-secondary-colour";
      }
      if (this.variant === "tertiary-colour") {
        return "cg-tertiary-colour";
      }
      if (this.variant === "tertiary-grey") {
        return "cg-tertiary-grey";
      }

      return "cg-primary";
    },
  },
});
</script>

<template>
  <b-button
    :size="size === 'sm' ? 'cg-sm' : 'cg-lg'"
    :variant="computedVariant"
    @click="$emit('click')"
    :disabled="disabled || loading"
    style="max-height: 44px"
    :block="block"
  >
    <slot v-if="text === undefined"></slot>
    <template v-if="loading">
      <div class="d-flex align-items-center align-middle">
        <b-spinner class="spinner-border-sm text-primary" role="status" data-cy="button-submit-spinner"></b-spinner>
        <span class="text-primary ml-2">{{ loadingText }}</span>
      </div>
    </template>
    <template v-else>
      <template v-if="iconPosition === 'leading'">
        <font-awesome-icon v-if="!bIcon && icon !== ''" :icon="icon" class="mr-1" />
        <b-icon v-else-if="icon !== '' && bIcon" :icon="icon" class="mr-1" />
      </template>
      {{ text }}
      <template v-if="iconPosition === 'trailing'">
        <font-awesome-icon v-if="!bIcon && icon !== ''" :icon="icon" class="ml-1" />
        <b-icon v-else-if="icon !== '' && bIcon" :icon="icon" class="ml-1" />
      </template>
    </template>
  </b-button>
</template>
